import _ from "lodash"

const smartChatHelper = (store) => {
  return {
    videoHandler: (object) => {
      object.video_error = true
    },
    getLinkData: (obj, key) => {
      return _.get(obj, `0.${key}`)
    },
    getEventType: (message) => {
      return _.get(message, "event.type")
    },
    getSenderType(message) {
      const id = _.get(message, "user.id")
      const role = _.get(message, "user.role")
      const is_bot =
        _.endsWith(id, "@bot") || id === "bot-default" || id === "default"
      const is_agent =
        (role === "admin" && !is_bot) || _.get(message, "is_dummy")
      const is_broadcast = _.has(message, "detected_broadcast")

      if (_.endsWith(id, "@inbox")) return "agent-inbox"
      if (is_bot && is_broadcast) return "broadcast"
      if (is_bot) return "bot"
      if (is_agent) return "agent"
      return "user"
    },
    getInboxSender: (room_platform) => {
      let platform_display_name
      if (room_platform === "facebook") {
        platform_display_name =
          store.getters.getContactPlatform("facebook-page")
      } else {
        platform_display_name = store.getters.getContactPlatform(room_platform)
      }

      return `ตอบผ่าน ${platform_display_name}`
    },
    getSender: (message, room_platform) => {
      let sender
      const user_name = message.user.name
      const user_display_name = message.user.display_name
      const sender_type = $nuxt.$smartChatHelper.getSenderType(message)

      switch (sender_type) {
        case "agent-inbox":
          sender = $nuxt.$smartChatHelper.getInboxSender(room_platform)
          break

        case "bot":
          sender = "แชทบอท"
          break

        case "broadcast":
          sender = "บรอดแคสต์"
          break

        default:
          sender = user_display_name
            ? user_display_name
            : user_name
            ? user_name
            : "สมาชิก"
          break
      }

      return sender
    },
    showConner(message, index) {
      const attachments = _.get(message, "attachments")
      if (
        message.show_profile_img &&
        !message.quick_replies &&
        attachments.length === 0
      ) {
        return true
      } else if (
        attachments.length > 0 &&
        (attachments[0] || {}).type === "file" &&
        message.show_profile_img
      ) {
        return true
      } else {
        return false
      }
    },
    highlightAllSearchMatches: (
      search = "",
      text = "",
      show_highlight_color = true,
      regex = null
    ) => {
      let result = null
      if (search && text) {
        const regex_pattern = !_.isEmpty(regex)
          ? regex
          : new RegExp(search, "i")
        const matches = text.match(regex_pattern)
        if (matches) {
          let tokens = text.split(new RegExp("(" + search + ")", "gi"))
          let available_tokens = _.map(tokens, (token) => {
            if (token.match(regex_pattern)) {
              return `<b ${
                show_highlight_color && 'style="color: #E64949"'
              }>${$nuxt.$textHelper.convertHtmlToString(token)}</b>`
            } else {
              return $nuxt.$textHelper.convertHtmlToString(token)
            }
          })

          result = _.join(available_tokens, "")
        }
      }
      return result
    },
    highlightSearchMatch: (
      search = "",
      text = "",
      show_highlight_color = true,
      regex = null,
      highlight_color = "#E64949",
    ) => {
      let result = null
      if (search && text) {
        const regex_pattern = !_.isEmpty(regex)
          ? regex
          : new RegExp(search, "i")
        const matches = text.match(regex_pattern)
        if (matches) {
          let match_str = _.get(matches, "[0]", "")
          let match_idx = matches.index
          let match_len = match_str.length
          let str_before_match_idx = text.slice(0, match_idx)
          let str_after_match_idx = text.slice(match_idx + match_len)

          result =
            $nuxt.$textHelper.convertHtmlToString(str_before_match_idx) +
            `<b ${
              show_highlight_color && `style="color: ${highlight_color}"`
            }>${$nuxt.$textHelper.convertHtmlToString(match_str)}</b>` +
            $nuxt.$textHelper.convertHtmlToString(str_after_match_idx)
        }
      }
      return result
    },
    checkIsBotSendFail: (message) => {
      const detected_pattern = _.get(message, "detected_pattern", undefined)
      const is_send_fail = _.get(message, "is_send_fail")
      return detected_pattern && is_send_fail
    },
    chatActionErrorMessage: (error) => {
      const code = _.get(error, "code")
      const message = _.get(
        error,
        "message",
        "เกิดข้อผิดพลาด กรุณาลองใหม่อีกครั้ง"
      )

      switch (code) {
        case 409:
          return "ไม่สามารถดำเนินการได้ เนื่องจากระบบกำลังประมวลผลอยู่"
        default:
          return message
      }
    },
  }
}

export default ({ app, store }, inject) => {
  inject("smartChatHelper", smartChatHelper(store))
}
