import _ from "lodash"

const RECONNECTION_DELAY = 1000
const RECONNECTION_DELAY_MAX = 5000
const MAX_RECONNECT_ATTEMPTS = 10
const DISCONNECT_REASONS = [
  "io server disconnect",
  "ping timeout",
  "transport close",
  "transport error",
]

const getReconnectDelay = (reconnect_attempts = 0) => {
  return Math.min(
    RECONNECTION_DELAY * Math.pow(2, reconnect_attempts),
    RECONNECTION_DELAY_MAX
  )
}

const state = () => ({
  oho_socket: null,
  reconnect_attempts: 0,
  is_connected: false,
  is_error: false,
  is_show_reload_chat_list_btn: false,
  is_show_chat_list_loader: false,
  is_reload_chat_list_after_reconnect_ws: false,
  is_reload_chat_room_after_reconnect_ws: false,
  is_tab_active: true,
  is_online: true,
  reconnect_timer: null,
})

const mutations = {
  setOhoSocket(state, socket) {
    state.oho_socket = socket
  },
  setWebsocketConnected(state, data) {
    state.is_connected = data
  },
  setWebsocketError(state, data) {
    state.is_error = data
  },
  incrementReconnectAttempts(state) {
    state.reconnect_attempts++
  },
  resetReconnectAttempts(state) {
    state.reconnect_attempts = 0
  },
  setIsReloadChatListAfterReconnectWS(state, data) {
    state.is_reload_chat_list_after_reconnect_ws = data
  },
  setIsReloadChatRoomAfterReconnectWS(state, data) {
    state.is_reload_chat_room_after_reconnect_ws = data
  },
  setIsShowReloadChatListBtn(state, data) {
    state.is_show_reload_chat_list_btn = data
  },
  setIsShowChatListLoader(state, data) {
    state.is_show_chat_list_loader = data
  },
  setTabActive(state, data) {
    state.is_tab_active = data
  },
  setNetworkStatus(state, data) {
    state.is_online = data
  },
  setReconnectTimer(state, data) {
    state.reconnect_timer = data
  },
}

const actions = {
  setupSocket({ dispatch, commit, state }, [socket, getAuth]) {
    if (!socket) return
    // Start connection, authentication and reconnection mechanism
    dispatch("setupConnectionLifeCycle", [socket, getAuth])

    // Here, define application-level events
    dispatch("registerEvents", socket)

    commit("setWebsocketConnected", true)
  },
  reconnectSocket({ state, commit }) {
    if (!state.is_tab_active || !state.is_online) return

    if (
      (!state.is_connected || !state.oho_socket.connected) &&
      state.reconnect_attempts <= MAX_RECONNECT_ATTEMPTS
    ) {
      commit("incrementReconnectAttempts")

      clearTimeout(state.reconnect_timer)
      const delay = getReconnectDelay(state.reconnect_attempts)
      const reconnect_timer = setTimeout(() => {
        state.oho_socket.connect()
      }, delay)

      commit("setReconnectTimer", reconnect_timer)
    } else {
      commit("setWebsocketError", true)
    }
  },
  setupConnectionLifeCycle({ state, commit, dispatch }, [socket, getAuth]) {
    // Basic socket.io reconnect mechanism
    // You don't need to change these in most cases
    socket.on("connecting", () => {
      if (!process.client) return

      this.$logger.info("Socket connecting")
    })

    socket.on("connect", async () => {
      if (!process.client) return

      // Enabled chat action
      if (!state.is_connected || !state.oho_socket.connected) {
        dispatch("handleChatListReload")
      }

      commit("setWebsocketConnected", true)
      commit("setWebsocketError", false)
      commit("resetReconnectAttempts")

      this.$logger.info("Socket connected. Socket ID:", socket.id)
      // Authenticate using current member's JWT token
      const authInfo = getAuth()
      socket.emit("login/member", authInfo)
    })

    socket.on("disconnect", (reason) => {
      if (!process.client) return

      commit("setWebsocketConnected", false)
      commit("setIsShowReloadChatListBtn", false)
      commit("setIsShowChatListLoader", false)

      if (DISCONNECT_REASONS.includes(reason)) {
        dispatch("reconnectSocket")
      }

      this.$logger.info(`Socket disconnected: ${reason}.`)
    })

    socket.on("connect_error", (error) => {
      if (!process.client) return

      dispatch("reconnectSocket")

      this.$logger.error(
        `Socket connected error: ${error.message}. Check if API/Socket server is down.`
      )
    })

    // IO manager
    socket.io.on("reconnect_attempt", (attempt) => {
      if (!process.client) return

      this.$logger.info("Socket reconnection attempt: ", attempt)
    })
    socket.io.on("reconnect", (attempt) => {
      if (!process.client) return

      this.$logger.info(`Socket reconnected in ${attempt} attempts!`)
    })
    socket.io.on("reconnect_error", (error) => {
      if (!process.client) return

      this.$logger.error(`Socket reconnection error: ${error.message}`)
    })
    socket.io.on("reconnect_failed", () => {
      if (!process.client) return

      this.$logger.warn(`Socket reconnection max attempt. Stop reconnection.`)
    })
    socket.io.on("error", (error) => {
      if (!process.client) return

      this.$logger.error(`Socket error occurred: ${error.message}`)
    })

    commit("setOhoSocket", socket)
  },
  resetReloadChatList({ commit }) {
    commit("setIsShowReloadChatListBtn", false)
    commit("setIsShowChatListLoader", false)
    commit("setIsReloadChatListAfterReconnectWS", false)
    commit("setIsReloadChatRoomAfterReconnectWS", false)
  },
  handleChatListReload({ commit }) {
    const room_list_el = document.querySelector("#room_list")
    const room_list_loader_el = document.querySelector("#room_list_loader")
    const room_list_loader_height = room_list_loader_el?.clientHeight || 0

    if (room_list_el) {
      const is_room_list_scrollable =
        room_list_el.scrollHeight > room_list_el.clientHeight
      const room_list_scroll_top = room_list_el.scrollTop || 0

      if (is_room_list_scrollable) {
        if (room_list_loader_height === 0 && room_list_scroll_top === 0) {
          room_list_el.scroll({ top: 2 })
        } else if (
          room_list_loader_height > 0 &&
          room_list_scroll_top > 0 &&
          room_list_scroll_top <= 50
        ) {
          room_list_el.scroll({ top: 50 })
        }

        commit("setIsShowReloadChatListBtn", true)
        commit("setIsShowChatListLoader", true)
      } else {
        commit("setIsShowReloadChatListBtn", true)
      }
    }
  },
  registerEvents({ rootState, commit, dispatch }, socket) {
    const is_onboarding = $nuxt.$route.path.split("/")[3] === "onboarding"
    // Here, define application-level events

    // Authenticate member OK
    // @param {string} member.userId
    // @param {string} member.businessId
    // @param {string} member.memberId
    // @param {string} member.role
    socket.on("login/member authenticated", (member) => {
      if (!process.client) return

      this.$logger.info("[socket] Member authenticated <3", member)
    })

    // New message arrives
    socket.on("chat/message created", async (message) => {
      if (!process.client) return

      if (is_onboarding) return
      // console.log("[socket] chat/message created:", message)
      await dispatch("handleUpdateContact", { event_message: message })
    })

    // New message my chat
    socket.on("chat/me/message created", async (message) => {
      if (!process.client) return

      if (is_onboarding) return
      // console.log("[socket] chat/me/message created:", message)
      await dispatch("newCustomerMessage", message)
      if (!rootState.window_focused) dispatch("setFavicon", "/favicon-noti.png")
    })

    // New message from no assignee contacts
    socket.on("chat/bot/message created", async (message) => {
      if (!process.client) return

      if (is_onboarding) return
      // console.log("[socket] chat/bot/message created:", message)
      await dispatch("newCustomerMessage", message)
      // if (!rootState.window_focused) dispatch("setFavicon", "/favicon-noti.png")
    })

    // New message from request contacts
    socket.on("chat/request/message created", async (message) => {
      if (!process.client) return

      if (is_onboarding) return
      // console.log("[socket] chat/request/message created:", message)
      await dispatch("newCustomerMessage", message)
      // if (!rootState.window_focused) dispatch("setFavicon", "/favicon-noti.png")
    })

    // New request
    socket.on("chat/request created", async (message) => {
      if (!process.client) return

      if (is_onboarding) return
      // console.log("[socket] chat/request ", message);
      await dispatch("newCustomerRequest", message)
      if (!rootState.window_focused) dispatch("setFavicon", "/favicon-noti.png")
    })

    // You're assigned to chatroom by the other admin
    socket.on("chat/assign created", async (message) => {
      if (!process.client) return

      if (is_onboarding) return
      // console.log("[socket] chat/assign ", message);
      await dispatch("youAssignedToChatroom", message)
      // this.$soundNoti()
      // if (!rootState.window_focused) dispatch("setFavicon", "/favicon-noti.png")
    })

    socket.on("chat/assign accepted", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      await dispatch("acceptedAssign", message)
      if (!rootState.window_focused) dispatch("setFavicon", "/favicon-noti.png")
    })

    socket.on("chat/assign canceled", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      await dispatch("canceledAssign", message)
      if (!rootState.window_focused) dispatch("setFavicon", "/favicon-noti.png")
    })

    socket.on("chat/assign rejected", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      await dispatch("rejectedAssign", message)
      if (!rootState.window_focused) dispatch("setFavicon", "/favicon-noti.png")
    })

    // Other unassigns you
    socket.on("chat/assign deleted", async (message) => {
      if (!process.client) return

      if (is_onboarding) return
      // console.log("[socket] chat/assign deleted", message);
      await dispatch("otherUnassignYou", message)
    })

    // Closed chat by the other admin
    socket.on("chat/close created", async (message) => {
      if (!process.client) return

      if (is_onboarding) return
      // console.log("[socket] chat/close ", message);
      await dispatch("closedChatByOtherAdmin", message)
    })

    // Sending bulk message
    socket.on("chat/me/bulk-message updated", async (message) => {
      if (!process.client) return

      if (is_onboarding) return
      // console.log("[socket] chat/close ", message);
      await dispatch("sendingBulkMessage", message)
    })

    socket.on("chat/status updated", async (message) => {
      if (!process.client) return

      if (is_onboarding) return
      await dispatch("setContactAggregate", message)
    })

    socket.on("team/member created", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      await dispatch("teamAddMember", message)
    })

    socket.on("team/member deleted", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      await dispatch("teamRemoveMember", message)
    })

    socket.on("member/online-status updated", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      await dispatch("updateMemberOnlineStatus", message)
    })

    socket.on("online-status aggregate", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      await dispatch("setOnlineStatusAggregate", message)
    })

    socket.on("contact/profile updated", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      await dispatch("updateContactData", { contact: message })
    })

    socket.on("contact/chat-tag updated", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      await dispatch("updateContactData", {
        contact: message,
        update_fields: ["labels"],
      })
    })

    socket.on("contact/tag updated", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      await dispatch("updateContactData", {
        contact: message,
        update_fields: ["tags"],
      })
    })

    socket.on("contact/notes updated", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      await dispatch("updateContactData", {
        contact: message,
        update_fields: ["notes"],
      })
    })

    socket.on("tag/contact_label updated", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      await dispatch("updateTagChat", { tag_chat: message, event: "edit" })
    })

    socket.on("tag/contact_label deleted", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      await dispatch("updateTagChat", { tag_chat: message, event: "delete" })
    })

    socket.on("tag/tag updated", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      await dispatch("updateContactTag", { contact_tag: message, event: "edit" })
    })

    socket.on("tag/tag deleted", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      await dispatch("updateContactTag", { contact_tag: message, event: "delete" })
    })

    socket.on("channel delete", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      if (_.get(message, "channel_id")) {
        await dispatch("informDeleteChannel")
        await dispatch("setOhoMemberPreferences", {
          ...state.oho_member_preferences,
          is_acked_channel_deletion: false,
        })
      }
    })

    socket.on("member/role updated", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      if (_.get(message, "acked_role_change.should_ack") === true) {
        commit("setAlertChangeRoleDialog", {
          role: _.get(message, "role"),
          changed_by: _.get(message, "acked_role_change.changed_by"),
          should_ack: true,
        })
      }
    })

    socket.on("contact/spam updated", async (message) => {
      if (!process.client) return
      if (is_onboarding) return

      await dispatch("handleUpdateContact", {
        event_message: {
          ...message,
          updated_at: message.is_spam_updated_at,
        },
        update_fields: ["is_spam", "is_spam_updated_by", "is_spam_updated_at"],
      })
    })

    /*
    Event name should be in this form: "{{resource}} {{method}}""
    - resource = Similar to Feathers services. For sub services, use slashes.
    - method = REST methods e.g. created, deleted, updated
    */
  },
  setFavicon({}, favicon) {
    const link = document.querySelector("link[rel~='icon']")

    if (!link) {
      link = document.createElement("link")
      link.rel = "icon"
      document.getElementsByTagName("head")[0].appendChild(link)
    }

    link.href = favicon
  },
}

export default { state, mutations, actions }
